import isMobile from "./helpers/mobile";
import Swiper from "swiper/bundle";
let open = false;
let isChanging = false;
const build = () => {
	if (isMobile()) {
		console.log("mobile programs");
		$("section.contributors .col-md-4").each(function () {
			$(this).addClass("col-md-12");
			$(this).removeClass("col-md-4");
		});
	}

	if (isMobile()) {
		let swiperStories = new Swiper("section.contributors .swiper", {
			slidesPerView: 1,
			loop: false,
			spaceBetween: 15,
			speed: 400,
			pagination: {
				el: "section.contributors .swiper-nav-wrapper .swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			navigation: {
				nextEl: "section.contributors .swiper-nav-wrapper .swiper-button-next",
				prevEl: "section.contributors .swiper-nav-wrapper .swiper-button-prev",
			},
		});
	}

	$("section.contributors a.btn").on("click", function (e) {
		e.preventDefault();
		var click = $(this).attr("data-prog");
		if (click != open) {
			openModal(click);
		}
	});

	$(".modal-programs .nav a.btn").on("click", function (e) {
		e.preventDefault();
		var click = $(this).attr("data-prog");

		// $('.modal-programs').removeClass(open);
		// $('.modal-programs').addClass(click);

		// open = click;
		isChanging = true;
		setTimeout(function () {
			openModal(click);
			isChanging = false;
		}, 600);
		closeModal(open);
	});

	$(".modal-programs .close").on("click", function (e) {
		closeModal();
	});
};

const openModal = (prog) => {
	document.querySelector(".modal-programs").scrollTop = 1;

	$("html").addClass("has-modal-open");
	$(".modal-programs").addClass(prog);
	$(".modal-programs .picture").addClass(
		"animate__fadeInRight animate__animated",
	);
	$(".modal-programs .content").addClass(
		"animate__fadeInLeft animate__animated",
	);
	$(".modal-programs .nav").addClass("animate__fadeInUp animate__animated");
	$(".modal-programs .close").addClass(
		"animate__fadeInDown animate__animated",
	);
	$(".modal-programs").show();
	open = prog;
};

const closeModal = (e) => {
	$("html").removeClass("has-modal-open");
	$(".modal-programs .picture").addClass("animate__fadeOutRight");
	$(".modal-programs .content").addClass("animate__fadeOutLeft ");
	$(".modal-programs .nav").addClass("animate__fadeOutDown");
	$(".modal-programs .close").addClass("animate__fadeOutUp");

	if (!isChanging) {
		$("html").removeClass("has-modal-open");
	}

	setTimeout(
		function () {
			console.log(open);
			$(".modal-programs").removeClass(open);
			$(".modal-programs").hide();
			$(".modal-programs .picture").removeClass(
				"animate__fadeInRight animate__animated",
			);
			$(".modal-programs .content").removeClass(
				"animate__fadeInLeft animate__animated",
			);
			$(".modal-programs .nav").removeClass(
				"animate__fadeInUp animate__animated",
			);
			$(".modal-programs .close").removeClass(
				"animate__fadeInDown animate__animated",
			);
			$(".modal-programs .picture").removeClass("animate__fadeOutRight");
			$(".modal-programs .content").removeClass("animate__fadeOutLeft ");
			$(".modal-programs .nav").removeClass("animate__fadeOutDown");
			$(".modal-programs .close").removeClass("animate__fadeOutUp");
			document.querySelector(".modal-programs").scrollTop = 1;

			open = false;
		},
		500,
		open,
	);
};

const resetModal = (e) => {
	return open;
};

export default () => {
	build();
};
