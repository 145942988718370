import isMobile from './helpers/mobile'
import Swiper from 'swiper/bundle';
let swiper;
let timeout;
let $ = jQuery;
let mapZoom = false;
const map = $('.interactive-map')
const close = $('.interactive-map .close')
const mapWrapper = $('.interactive-map .wrapper');
const nations = $('.nation');
const labels = $('.nation .labels');
let innerSwiper = null;

//document.documentElement.style.setProperty('--animate-duration', '2s');

const build = () => {


    if (!isMobile()) {

        $('.nation svg path, .nation .label').on('click', function() {
            onOpenClick($(this).closest('.nation').attr('data-nation'));
        })
        close.on('click', function() {
            onCloseClick();
        })

        // document.querySelectorAll('.interactive-map .each-content').forEach(function(el) {
        //     el.classList.forEach(function(cl) {
        //         if (cl != 'each-content') {
        //             let currentClass = '.interactive-map .each-content.' + cl + ' .swiper';
        //             let assetsClass = '.interactive-map .each-content.' + cl;

        //             let currentSlides = document.querySelectorAll('.interactive-map .each-content.' + cl + ' .swiper .swiper-slide');

        //             console.log(currentSlides.length);
        //             if (currentSlides.length > 1) {
        //                 let swiperArray = new Swiper(currentClass, {
        //                     slidesPerView: 1,
        //                     loop: true,
        //                     speed: 400,
        //                     pagination: {
        //                         el: assetsClass + ' .swiper-nav-wrapper .swiper-pagination',
        //                         clickable: true,

        //                     },

        //                     navigation: {
        //                         nextEl: assetsClass + ' .swiper-nav-wrapper .swiper-button-next',
        //                         prevEl: assetsClass + ' .swiper-nav-wrapper .swiper-button-prev',
        //                     },

        //                 });
        //             } else {
        //                 $(assetsClass + ' .swiper-nav-wrapper').hide();

        //             }
        //         }

        //     })



        // });
    }




}

const onOpenClick = nation => {

    $('#header').addClass('auto-scrolling');
    //Adjust Scroll
    var scrollPosition;
    // if (map.height() > $(window).height()) {

    //     scrollPosition = map.height() - $(window).height();
    //     scrollPosition = scrollPosition / 2;
    //     scrollPosition = map.offset().top + scrollPosition;
    // } else {
    //     scrollPosition = $(window).height() - map.height();
    //     scrollPosition = scrollPosition / 2;
    //     scrollPosition = map.offset().top - scrollPosition;
    // }

    // $('html,body').animate({
    //     scrollTop: scrollPosition
    // }, 200);
    $('html').addClass('has-modal-open');
    setTimeout(function() { $('#header').removeClass('auto-scrolling') }, 1010)

    if (!mapZoom) {
        handleOpenClick(nation);
    } else {
        //$('.each-content.visible').addClass('animate__flipOutY');
        setTimeout(function() {
            handleOpenClick(nation);

        }, 500)
    }

}

const handleOpenClick = nation => {
    map.addClass('zoom');
    $('.each-content').removeClass('animate__fadeOutRight');
    $('.each-map').removeClass('animate__fadeOutLeft');


    //Handle map class change
    mapWrapper.removeClass();
    mapWrapper.addClass('wrapper');
    nations.addClass('hidden');
    $('.' + nation).removeClass('hidden');
    $('.' + nation).addClass('highlight');
    mapWrapper.addClass('highlight');
    mapWrapper.addClass('highlight-' + nation);

    //Handle content class changes
    $('.each-content').removeClass('animate__flipOutY');
    $('.each-content').removeClass('visible');
    $('.each-content.content-' + nation).addClass('visible animate__animated animate__fadeInRight');

    $('.each-map').removeClass('animate__flipOutY');
    $('.each-map').removeClass('visible');
    $('.each-map.content-' + nation).addClass('visible animate__animated animate__fadeInLeft');

    mapZoom = true;

    setSwiper(nation);
}

const setSwiper = nation => {
    let cl = 'content-' + nation;
    let currentClass = '.interactive-map .each-content.' + cl + ' .swiper';
    let assetsClass = '.interactive-map .each-content.' + cl;

    let currentSlides = document.querySelectorAll('.interactive-map .each-content.' + cl + ' .swiper .swiper-slide');

    if (currentSlides.length > 1) {
        innerSwiper = new Swiper(currentClass, {
            slidesPerView: 1,
            loop: true,
            speed: 400,
            pagination: {
                el: assetsClass + ' .swiper-nav-wrapper .swiper-pagination',
                clickable: true,

            },

            navigation: {
                nextEl: assetsClass + ' .swiper-nav-wrapper .swiper-button-next',
                prevEl: assetsClass + ' .swiper-nav-wrapper .swiper-button-prev',
            },

        });
    } else {
        $(assetsClass + ' .swiper-nav-wrapper').hide();

    }
}

const onCloseClick = e => {

    if (innerSwiper) {
        console.log('destroy swipper');
        innerSwiper.destroy(true, true);
    }
    mapWrapper.removeClass();
    mapWrapper.addClass('wrapper');
    nations.removeClass('hidden');
    $('html').removeClass('has-modal-open');
    $('.each-content.visible').addClass('animate__fadeOutRight');
    $('.each-map.visible').addClass('animate__fadeOutLeft');
    setTimeout(function() {
        map.removeClass('zoom');
    }, 400);


    mapZoom = false;
}


const onResize = e => {
    clearTimeout(timeout)
    timeout = setTimeout(() => build(), 500)
}

export default () => {
    build()
    window.addEventListener('resize', onResize)
}
