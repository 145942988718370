//import "animate.css";
import isMobile from "./helpers/mobile";
import Header from "./00-header";
import Mapslider from "./06-mapslider";
import ImpactMap from "./06-impact-map";
// import Impact from './06-impact';
import Icons from "./04-icons";
// import Stories from './05-stories';
// import Supporters from './10-supporters';
// import Campaign from './11-campaign';
// import InAction from './13-in-action';
import Process from "./07-process";
import Contributors from "./08-contributors";

// new Stories();
new Contributors();
// new Supporters();
// new Campaign();
// new InAction();
new Icons();
new Header();
new Mapslider();
new ImpactMap();
new Process();
// new Impact();

$("a.learn-more.read-more").on("click", function (e) {
	e.preventDefault();
	var currentclass = $(this).attr("data-section");
	$(this).toggleClass("active");
	$("." + currentclass + " p.extra").toggleClass("active");
});
if (isMobile()) {
	let globalFindings = new Accordion(
		".global-findings .accordion-container",
		{
			duration: 400,
			showMultiple: false,
			beforeOpen: function (currentElement) {
				$(".global-findings .accordion-container .ac").removeClass(
					"next",
				);
				var nextElelemnt = parseInt(currentElement.id.substring(3)) + 1;
				nextElelemnt = "#ac-" + nextElelemnt;
				$(nextElelemnt).addClass("next");
				// console.log(nextEl);
				// console.log(
				// 	$(
				// 		".section-findings .accordion-container ac#" +
				// 			currentElement.id +
				// 			1,
				// 	),
				// );
			},
			beforeClose: function (currentElement) {
				$(".global-findings .accordion-container .ac").removeClass(
					"next",
				);
			},
		},
	);
	globalFindings.open(0);

	let keyRecommendations = new Accordion(
		".recommendations .accordion-container",
		{
			duration: 400,
			showMultiple: false,
			beforeOpen: function (currentElement) {
				$(".recommendations .accordion-container .ac").removeClass(
					"next",
				);
				var nextElelemnt = parseInt(currentElement.id.substring(3)) + 1;
				nextElelemnt = "#ac-" + nextElelemnt;
				$(nextElelemnt).addClass("next");
				// console.log(nextEl);
				// console.log(
				// 	$(
				// 		".section-findings .accordion-container ac#" +
				// 			currentElement.id +
				// 			1,
				// 	),
				// );
			},
			beforeClose: function (currentElement) {
				$(".recommendations .accordion-container .ac").removeClass(
					"next",
				);
			},
		},
	);
	keyRecommendations.open(0);
}

$(".section-findings ul li").on("click", function () {
	if (!$(this).hasClass("active")) {
		$(".section-findings ul li").removeClass("active");
		$(this).addClass("active");

		let datafinding = $(this).children("a").attr("data-finding");
		//datafinding = "[data-finding='" + datafinding + "']";
		$(".section-findings .each-finding").removeClass("active");
		//$('[data-finding="2"]').addClass("active",);
		$(datafinding).addClass("active");

		$(
			".section-findings .each-finding[data-finding='" +
				datafinding +
				"']",
		).addClass("active");
	}
});

if (isMobile()) {
	AOS.init({
		// Global settings:
		disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
		startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
		initClassName: "aos-init", // class applied after initialization
		animatedClassName: "aos-animate", // class applied on animation
		useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
		disableMutationObserver: false, // disables automatic mutations' detections (advanced)
		debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
		throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

		// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
		offset: 120, // offset (in px) from the original trigger point
		delay: 0, // values from 0 to 3000, with step 50ms
		duration: 800, // values from 0 to 3000, with step 50ms
		easing: "ease-in-out", // default easing for AOS animations
		once: false, // whether animation should happen only once - while scrolling down
		mirror: false, // whether elements should animate out while scrolling past them
		anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
	});
} else {
	AOS.init({
		// Global settings:
		disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
		startEvent: "DOMContentLoaded", // name of the event dispatched on the document, that AOS should initialize on
		initClassName: "aos-init", // class applied after initialization
		animatedClassName: "aos-animate", // class applied on animation
		useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
		disableMutationObserver: false, // disables automatic mutations' detections (advanced)
		debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
		throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)

		// Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
		offset: 120, // offset (in px) from the original trigger point
		delay: 0, // values from 0 to 3000, with step 50ms
		duration: 800, // values from 0 to 3000, with step 50ms
		easing: "ease-in-out", // default easing for AOS animations
		once: false, // whether animation should happen only once - while scrolling down
		mirror: false, // whether elements should animate out while scrolling past them
		anchorPlacement: "top-bottom", // defines which position of the element regarding to window should trigger the animation
	});
}

function hasTouch() {
	return (
		"ontouchstart" in document.documentElement ||
		navigator.maxTouchPoints > 0 ||
		navigator.msMaxTouchPoints > 0
	);
}

if (hasTouch()) {
	// remove all the :hover stylesheets
	try {
		// prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match(":hover")) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) {}
}

// const headerplayer = new Plyr(".banner .header-player", {
// 	controls: false,
// 	muted: true,
// 	playsinline: true,
// 	autoplay: true,
// });
// headerplayer.muted = true;
// headerplayer.volume = 0;
// headerplayer.play();

// $("body").on("touchstart", function (e) {
// 	headerplayer.play();
// });

// document.addEventListener('DOMContentLoaded', () => {
//     const controls = [
//         //'play-large', // The large play button in the center
//         //'restart', // Restart playback
//         //'rewind', // Rewind by the seek time (default 10 seconds)
//         //'play', // Play/pause playback
//         //'fast-forward', // Fast forward by the seek time (default 10 seconds)
//         //'progress', // The progress bar and scrubber for playback and buffering
//         //'current-time', // The current time of playback
//         //'duration', // The full duration of the media
//         //'mute', // Toggle mute
//         //'volume', // Volume control
//         //'captions', // Toggle captions
//         //'settings', // Settings menu
//         //'pip', // Picture-in-picture (currently Safari only)
//         //'airplay', // Airplay (currently Safari only)
//         //'download', // Show a download button with a link to either the current source or a custom URL you specify in your options
//         //'fullscreen' // Toggle fullscreen
//     ];
//     const player = Plyr.setup('.banner .js-player', { controls, muted: true, playsinline: true, autoplay: true });
//     player.muted = true;
//     player.volume = 0;
//     player.play();

// });

// import Rellax from 'rellax';
// var rellax = new Rellax('.rellax');
// window.addEventListener("load", () => {
//     let rellax = new Rellax('.rellax', {
//         wrapper: 'body'
//     });
// });

// console.log('2');

window.onload = function () {
	var rellax = new Rellax(".rellax");
};

$("ul.inner-title.board").on("click", function (e) {
	$(".inner-title.board").toggleClass("active");
});

$(".financials .graph").on("mouseenter", function () {
	$(this)
		.find("img.gif")
		.attr("data-png", $(this).find("img.gif").attr("src"));
	$(this)
		.find("img.gif")
		.attr("src", $(this).find("img.gif").attr("data-gif"));
});

$(".financials .graph").on("mouseleave", function () {
	$(this)
		.find("img.gif")
		.attr("src", $(this).find("img.gif").attr("data-png"));
});

if (isMobile()) {
	// remove all the :hover stylesheets
	try {
		// prevent exception on browsers not supporting DOM styleSheets properly
		for (var si in document.styleSheets) {
			console.log("remove mobile");
			var styleSheet = document.styleSheets[si];
			if (!styleSheet.rules) continue;

			for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
				if (!styleSheet.rules[ri].selectorText) continue;

				if (styleSheet.rules[ri].selectorText.match(":hover")) {
					styleSheet.deleteRule(ri);
				}
			}
		}
	} catch (ex) {}
}

const lightbox = GLightbox({
	selector: ".glightbox",
	afterSlideLoad: function (slideData) {
		let player = slideData.player;
		if (player) {
			if (!player.ready) {
				player.on("ready", (event) => {
					player.increaseVolume(1);
				});
			} else {
				player.increaseVolume(1);
			}
		}
	},
	touchNavigation: true,
	loop: true,
	autoplayVideos: true,
	plyr: {
		// css: 'https://cdn.plyr.io/3.5.6/plyr.css', // Default not required to include
		// js: 'https://cdn.plyr.io/3.5.6/plyr.js', // Default not required to include
		config: {
			ratio: "16:9", // or '4:3'

			hideControls: false,
			autoplay: true,

			youtube: {
				noCookie: true,
				rel: 0,
				showinfo: 0,
				iv_load_policy: 3,
			},
			vimeo: {
				byline: false,
				portrait: false,
				title: false,
				speed: true,
				transparent: false,
			},
		},
	},
});

window.onload = function () {
	var rellax = new Rellax(".rellax");
	rellax.refresh();
};
