import Swiper from "swiper/bundle";

let swiper;
let lastScrollTop = 0;
let oTop = $("#header").height() * 2;

const build = () => {
	swiper = new Swiper(".banner .swiper", {
		loop: false,
		speed: 600,
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		autoplay: {
			delay: 3000,
		},
	});

	window.addEventListener(
		"scroll",
		function () {
			// or window.addEventListener("scroll"....
			var st = window.pageYOffset || document.documentElement.scrollTop; // Credits: "https://github.com/qeremy/so/blob/master/so.dom.js#L426"
			if (st > lastScrollTop) {
				if ($(window).scrollTop() > oTop) {
					$("#header").addClass("hidden");
					$("a.nav-handler").removeClass("active");
					$("#menu").removeClass("active");
				}
			} else {
				if (!$("#header").hasClass("auto-scrolling")) {
					$("#header").removeClass("hidden");
				}
			}
			lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
		},
		false,
	);

	$(".menu-toggle").on("click", function (e) {
		e.preventDefault();
		$(this).toggleClass("open");
		$("#menu").toggleClass("active");
		$("#full-menu").toggleClass("open");
		$("html").toggleClass("has-modal-open");
	});

	$("#menu ul li a").on("click", function (e) {
		if ($(".menu-toggle").hasClass("open")) {
			$(".menu-toggle").trigger("click");
		}
	});

	let sectionArray = [
		"top",
		"a-global-consultation",
		"who-we-consulted",
		"key-global-findings",
		"country-snapshots",
		"our-approach",
		"meet-some-of-our-contributors",
		"key-recommendations",
		"whats-next",
	];
	let sectionOffsetArray = Array();
	let lastOffset = -1;
	let currentOffset = 0;

	$("section").each(function () {
		if (sectionArray.includes(this.id)) {
			sectionOffsetArray.push(this.offsetTop);
		}
	});

	$(window).on("scroll", function () {
		let scrollPosition = window.scrollY + window.innerHeight / 2;

		if (
			scrollPosition >= sectionOffsetArray[0] &&
			scrollPosition <= sectionOffsetArray[1]
		) {
			handleProgressBar(0);
		} else if (
			scrollPosition >= sectionOffsetArray[1] &&
			scrollPosition <= sectionOffsetArray[2]
		) {
			handleProgressBar(1);
		} else if (
			scrollPosition >= sectionOffsetArray[2] &&
			scrollPosition <= sectionOffsetArray[3]
		) {
			handleProgressBar(2);
		} else if (
			scrollPosition >= sectionOffsetArray[3] &&
			scrollPosition <= sectionOffsetArray[4]
		) {
			handleProgressBar(3);
		} else if (
			scrollPosition >= sectionOffsetArray[4] &&
			scrollPosition <= sectionOffsetArray[5]
		) {
			handleProgressBar(4);
		} else if (
			scrollPosition >= sectionOffsetArray[5] &&
			scrollPosition <= sectionOffsetArray[6]
		) {
			handleProgressBar(5);
		} else if (
			scrollPosition >= sectionOffsetArray[6] &&
			scrollPosition <= sectionOffsetArray[7]
		) {
			handleProgressBar(6);
		} else if (
			scrollPosition >= sectionOffsetArray[7] &&
			scrollPosition <= sectionOffsetArray[8]
		) {
			handleProgressBar(7);
		} else if (scrollPosition >= sectionOffsetArray[8]) {
			handleProgressBar(8);
		}
	});

	function handleProgressBar(i) {
		$("#progress-bar li").removeClass("active");

		if (
			!$('#progress-bar li a[href*="#' + sectionArray[i] + '"]').hasClass(
				"active",
			)
		) {
			$('#progress-bar li a[href*="#' + sectionArray[i] + '"]')
				.parent("li")
				.addClass("active");
		}
	}
};

export default () => {
	build();
};
