let isMobile = false;

const build = () => {

    if (window.innerWidth <= 820) {
        console.log(window.innerHeight + '   primeiro if   ' + window.innerWidth)
        if (window.innerHeight >= window.innerWidth) {
            isMobile = true;
        } else {
            isMobile = false;
        }
    } else {
        isMobile = false;
    }
}


export default () => {
    build();
    return isMobile;
}
